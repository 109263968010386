import { Injectable } from '@angular/core';
import { SharedUtils } from './utils';

interface UrlMapping {
    readonly [key: string]: string;
}

interface QueryParamConfig {
    readonly maxLength: number;
    readonly possibleQueryParams: {
        readonly [key: string]: string;
    };
}
  
interface UrlDecodeMapping {
    readonly [key: string]: QueryParamConfig;
}
  
@Injectable({
  providedIn: 'root'
})
export class UrlShortenedService {

    urlEncodeGuide: UrlMapping = {
        // url elements
        "customer": "cu",        
        "free-course": "fc",
        "double": "do",
        "dashboard": "da",

        // query params
        "course_id": "CI",
        "lesson_public": "LP"
    }

    urlDecodeGuide: UrlDecodeMapping = {
        "cu": {
            maxLength: 3,
            possibleQueryParams: {
                "CI": "course_id",
                "LP": "lesson_public"
            }
        }
    }

    urlPossibleQueryParams = ['course_id', 'lesson_public'];

    constructor() {}

    /**
     * Encodes a URL into a shortened format using predefined mappings.
     * @param url - The full URL to be shortened
     * @returns The shortened URL
     * @throws {Error} If the URL is invalid or contains unmapped segments
     */
    encodeUrl(url: string): string {
        try {
            // BEGINNING OF THE REQUIRED VARIABLES
            // building url object from url string
            const tempUrl = new URL(url);
            // building url query params object
            const urlParams = new URLSearchParams(tempUrl.search);
            let paramPairs = [];
            let newUrlArr = [];
            let explodedUrl = tempUrl.pathname.replace('/ng/', '/').split('/');
            // remove empty array elements
            explodedUrl = explodedUrl.filter(n => n);
            // END
            
            // building an array with shortened reference keys from guide
            for(let i = 0; i < explodedUrl.length; i++) {
                const encodedSegment = this.urlEncodeGuide[explodedUrl[i]];
                if (!encodedSegment) {
                    throw new Error(`Unknown URL segment: ${explodedUrl[i]}`);
                }
                newUrlArr.push(encodedSegment);
            }
            // building an array with value of query param from guide
            for(let i = 0; i <  this.urlPossibleQueryParams.length; i++) {
                if(urlParams.get(this.urlPossibleQueryParams[i])) {
                    const encodedParam = this.urlEncodeGuide[this.urlPossibleQueryParams[i]];
                    if (!encodedParam) {
                        throw new Error(`Unknown query parameter: ${this.urlPossibleQueryParams[i]}`);
                    }
                    const paramValue = urlParams.get(this.urlPossibleQueryParams[i]);
                    paramPairs.push(encodedParam + paramValue);
                }
            }
            // creating a new shortened link
            let newUrl = tempUrl.origin + '/ng/SU' + newUrlArr.join('')+paramPairs.join('');
            return newUrl;
        } catch (error) {
            throw new Error(`Failed to encode URL: ${error.message}`);
        }        
    }

    /**
     * Decodes a shortened URL back to its original format.
     * @param url - The shortened URL to decode
     * @returns The original URL
     * @throws {Error} If the URL format is invalid or cannot be decoded
     */
    decodeUrl(url: string): string {
        try {
            // BEGINNING OF THE REQUIRED VARIABLES
            const tempUrl = new URL(url);
            const pathname = tempUrl.pathname.split('/')[1].substring(2);
            const invertedUrlEncodeGuide = SharedUtils.flipObject(this.urlEncodeGuide);
            let urlDecodeGuide;
            let newUrlArr = [];
            let maxLimit;
            let queryParamAfter;
            // END

            // get the decode guide and the maximum limit of encoded url elements
            if(this.urlDecodeGuide[pathname.slice(0, 2)]) {
                urlDecodeGuide = this.urlDecodeGuide[pathname.slice(0, 2)];
                maxLimit = urlDecodeGuide.maxLength;
            }
            // decode encoded url elements
            for(let i = 0; i < maxLimit; i++) {
                // get the encoded url element of two letters from the string
                let urdEncodedKey = pathname.slice(i + i * 1, (i+1) * 2);
                // check if the element is indeed the encoded url and not the query param
                if(!SharedUtils.isUpperCase(urdEncodedKey)) {
                    newUrlArr.push(invertedUrlEncodeGuide[urdEncodedKey]);
                } else {
                    continue;
                }
                // set query param start point
                queryParamAfter = i+1;
            }
            // creating a new shortened link
            let newUrl = newUrlArr.join('/');
            // checking if shortened url contain query param
            if(pathname.length > 2*maxLimit) {
                // add query param to the url string using decode guide
                newUrl = this.createNewUrlWithQueryParamsForDecode(newUrl, urlDecodeGuide, pathname, queryParamAfter);
            }
            return newUrl;
        } catch (error) {
            throw new Error(`Failed to decode URL: ${error.message}`);
        }   
    }

    /**
     * Build a new link with the query parameter
     * @param url - The new decoded URL
     * @param urlDecodeGuide - The URL decode guide with possible query params keys
     * @param pathname - The shortened URL with query param
     * @param queryParamAfter - A number that indicates how many 2-character sections go from the beginning of the encrypted URL to the parameter
     * @returns New url with query params
     */
    createNewUrlWithQueryParamsForDecode(url: string, urlDecodeGuide: UrlDecodeMapping, pathname: string, queryParamAfter: number): string {
        // get query param key from encoded url with urlDecodeGuide
        const queryKey = urlDecodeGuide.possibleQueryParams[pathname.slice(2*queryParamAfter,2*queryParamAfter+2)];
        // get query param value from encoded url
        const queryValue = pathname.slice(2*queryParamAfter+2);
        return url+'?'+queryKey+'='+queryValue;
    }
}
