import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FileManagerService } from './services/file-manager.service';
import { environment } from '../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FacebookPixelService } from './services/facebook-pixel.service';
import { AlertService } from './services/alert.service';
import { GlobalEventManagerService } from './services/global-event-manager.service';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';
import { MobileAppService } from '@coreservices/mobile-app.service';
import { Observable, forkJoin } from 'rxjs';
import { EntityService } from '@coreservices/entity.service';
import { UserService } from '@coreservices/user/user.service';
import { LoginStartupService } from '@coreservices/login-startup.service';
import { StaticDataService } from '@coreservices/static-info.service';
@UntilDestroy()
@Component({
  selector: 'app-core',
  templateUrl: './core.component.html'
})

/**
 * Bootstrap component
 * load authentication form or maps
 */
export class CoreComponent {

  isDataReady: boolean = false;
  isHideProgressBar: boolean = false;

  settingsDesign: any;

  constructor(private _translateService: TranslateService,
              private _fileManagerService: FileManagerService,
              private _userService: UserService,
              private _entityService: EntityService,
              private _router: Router,
              private _route: ActivatedRoute,
              private _location: Location,
              private _facebookPixelService: FacebookPixelService,
              private _alert: AlertService,
              private _globalEventManagerService: GlobalEventManagerService,
              private _ssr: ServerSideRenderingService,
              private _staticDataService: StaticDataService,
              public loginStartupService: LoginStartupService) {
  }



  ngOnInit() {
    if(this._ssr.isBrowser) {
      this._facebookPixelService.start();
      let prevPath = this._location.path();
      this._router
        .events
        .filter(event => (event instanceof NavigationEnd))
        .subscribe(() => {
          const newPath = this._location.path();
          if (this._router.url.indexOf('/admin/media_storage') !== -1) {
            this.isHideProgressBar = true;
          } else {
            this.isHideProgressBar = false;
          }
          prevPath = newPath;
        });

      // init language
      this._translateService.addLangs(['en', 'de', 'sk', 'cz', 'hu', 'mk']);
      let langCode: string = (environment.production) ? 'de' : 'en';
      this._translateService.use(langCode);

      const languageSettings$: Observable<any> = this._entityService.getPortalEntity('portal/language');
      const portalSettings$: Observable<any> = this._entityService.getPortalEntity('entity/portal', { columns: ['id', 'is_active', 'is_leader_board_shown', 'is_vuexy_tester'] });

      const combinedSources: Observable<any> = forkJoin([
        languageSettings$, portalSettings$
      ]);
      combinedSources
        .pipe(untilDestroyed(this))
        .subscribe(
          ([language_settings, portal]) => {
            if(!window.location.pathname.includes('signup')) {
              this._translateService.use(language_settings.code);
            }
            this._userService.setPortal(portal);
          },
          error => {
            if (!window.localStorage.getItem('portal') || !window.location.href.includes(window.localStorage.getItem('portal')['domain'])) {
              this._router.navigateByUrl('/login')
            }
            console.error(error)
          }
        );
      this.subscribeShowAlert();

      // image/get issues checker: Remove after tests of migration to new fileserver
      this._fileManagerService.fileIssuesChecker();

      MobileAppService.setListener(this._router);

      this.loginStartupService.setStartupDesignStaticData();
    };
  }

  subscribeShowAlert(): void {
    this._globalEventManagerService.$showAlert.pipe(untilDestroyed(this)).subscribe(alertObj => {
      if (alertObj.event_status) {
        if (alertObj.event_status === 'success') {
          this._alert.showSuccess(alertObj.event_type, alertObj.event_entity);
        } else if (alertObj.event_status === 'custom') {
          this._alert.showCustomError(alertObj.event_message, alertObj.event_entity);
        } else {
          this._alert.showError(alertObj.event_type, alertObj.event_entity);
        }
      }
    });
  }


}
